import { Link } from "react-router-dom";

export default function Categories({ className, data, baseUrl }) {
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="grid xl:grid-cols-12 lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 xl:gap-[10px] gap-2">
        {data?.map((item, index) => (
          <div
            key={index}
            data-aos="fade-left"
            data-aos-duration="500"
            className="item w-full flex flex-col items-center"
          >
            <Link to={`/all-products?categories=${item?.id}`}>
              <div className="w-[80px] h-[80px] rounded-full bg-white flex justify-center items-center overflow-hidden mb-2">
                <img
                  src={baseUrl + item.image}
                  alt=""
                />
              </div>
              <p className="text-base font-500 text-center" style={{ fontSize: '12px' }}>{item?.name}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
