import { get } from '..';
import ShopRoutes from './Shop.Routes';

const ShopServices = {
  getShopDetailById: async (id) => {
    const data = await get(ShopRoutes.getShopDetailById + id);
    return data;
  },
  getTopSellers: async (params) => {
    const data = await get(ShopRoutes.getTopSellers, params);
    return data;
  },
  getUserDetail: async () => {
    const data = await get(ShopRoutes.getUserDetail);
    return data;
  },
}

export default ShopServices