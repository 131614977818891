import { useEffect, useRef, useState } from "react";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { businessMobileRegex, Debounce, emailRegex, mobileRegex, passwordRegex } from "../../utils";
import AuthServices from "../../apis/auth/AuthServices";
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import CommonServices from "../../apis/common/CommonServices";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function BecomeSaller() {

  const { state } = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const chequeInput = useRef(null);
  const fbrInput = useRef(null);
  const cnicFrontInput = useRef(null);
  const cnicBackInput = useRef(null);

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // *For Documents
  const [fbrLetter, setFbrLetter] = useState();
  const [chequeBookSlip, setChequeBookSlip] = useState();
  const [cnicFront, setCnicFront] = useState();
  const [cnicBack, setCnicBack] = useState();

  const [chequeBookFile, setChequeBookFile] = useState('');
  const [fbrFile, setFbrFile] = useState('');
  const [cnicFrontFile, setCnicFrontFile] = useState('');
  const [cnicBackFile, setCnicBackFile] = useState('');

  // *For Select
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  // *For Cities Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // *For Upload Image
  const handleUploadImage = (e, type) => {
    if (e.target.value !== "") {
      if (type === 'chequeBook') {
        setChequeBookFile(e.target.files[0])
      } else if (type === 'fbr') {
        setFbrFile(e.target.files[0])
      } else if (type === 'cnicFront') {
        setCnicFrontFile(e.target.files[0])
      } else if (type === 'cnicBack') {
        setCnicBackFile(e.target.files[0])
      }
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        if (type === 'chequeBook') {
          setChequeBookSlip(event.target.result);
        } else if (type === 'fbr') {
          setFbrLetter(event.target.result);
        } else if (type === 'cnicFront') {
          setCnicFront(event.target.result);
        } else if (type === 'cnicBack') {
          setCnicBack(event.target.result);
        }
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  // *For Registration
  const registration = async (data) => {
    setLoading(true)
    try {
      if (!selectedCountry) {
        ErrorToaster('Pease select country')
        return
      }
      if (!selectedCity) {
        ErrorToaster('Pease select city')
        return
      }
      if (!chequeBookFile) {
        ErrorToaster('Pease upload cheque book slip')
        return
      }
      if (!fbrFile) {
        ErrorToaster('Pease upload FBR Letter')
        return
      }
      if (!cnicFrontFile) {
        ErrorToaster('Pease upload CNIC front picture')
        return
      }
      if (!cnicBackFile) {
        ErrorToaster('Pease upload CNIC back picture')
        return
      }
      let obj = {
        name: data.name,
        username: data.username,
        phone: data.phone,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        cnic: data?.cnic,
        userType: state?.userType,
        businessName: data.businessName,
        brandName: data.brandName,
        businessContact: data.businessContact,
        ntn: data.ntn,
        strn: data.strn,
        businessCountry: selectedCountry?.id,
        businessCity: selectedCity?.id,
        businessPostalCode: data.businessPostalCode,
        businessAddress: data.businessAddress,
        warehouseAddress: data.warehouseAddress,
        chequeBookFile: chequeBookFile,
        fbrFile: fbrFile,
        cnicFrontFile: cnicFrontFile,
        cnicBackFile: cnicBackFile,
        bankName: data.bankName,
        accountName: data.accountName,
        accountNumber: data.accountNumber,
        iban: data.iban,
      }
      await AuthServices.checkEmailPhone(data.email, data.phone)
      navigate('/membership-plan', { state: obj })
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Get Countries
  const getCountries = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCountries(params)
      setCountries(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCountries ~ error:", error)
    }
  }

  // *For Get Cities
  const getCities = async (page, country, search) => {
    try {
      const countryId = country ? country.id : selectedCountry?.id
      const Page = page ? page : currentPage
      setCurrentPage(Page)
      let params = {
        search: search,
        page: page,
        limit: 100
      }
      const { data, count } = await CommonServices.getCities(countryId, params)
      setCities(data)
      setTotalCount(count)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCities ~ error:", error)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getCountries()
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="become-saller-wrapper w-full">
        <div className="title mb-10">
          <PageTitle
            title="Registration Application"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Register", path: "/register" },
            ]}
          />
        </div>
        <div className="content-wrapper w-full mb-10">
          <div className="container-x mx-auto">
            <div className="w-full bg-white sm:p-[30px] p-3">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-11" style={{ justifyContent: 'space-evenly' }}>
                <form className="xl:w-[824px]" onSubmit={handleSubmit(registration)}>
                  <div className="title w-full mb-4">
                    <h1 className="text-[22px] font-semibold text-qblack mb-1">
                      Registration Information
                    </h1>
                    <p className="text-[15px] text-qgraytwo">
                      Fill the form below or write us .We will help you as soon
                      as possible.
                    </p>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Name"
                        label="Name*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.name?.message}
                        register={register("name", {
                          required: 'Please enter a name.',
                        })}
                      />

                      <InputCom
                        placeholder="username"
                        label="Username"
                        type="text"
                        inputClasses="h-[50px]"
                        register={register("username")}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="example@email.com"
                        label="Email Address*"
                        type="email"
                        inputClasses="h-[50px]"
                        errors={errors?.email?.message}
                        register={register("email", {
                          required: 'Please enter an email address.',
                          pattern: {
                            value: emailRegex,
                            message: 'Please enter a valid email.',
                          }
                        })}
                      />

                      <InputCom
                        placeholder="+92312 *********"
                        label="Phone*"
                        type="tel"
                        inputClasses="h-[50px]"
                        errors={errors?.phone?.message}
                        register={register("phone", {
                          required: 'Please enter a phone number.',
                          pattern: {
                            value: mobileRegex,
                            message: 'Please enter a mobile number.',
                          }
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="CNIC"
                        label="CNIC*"
                        type="text"
                        inputClasses="h-[50px]"
                        minLength={13}
                        maxLength={13}
                        errors={errors?.cnic?.message}
                        register={register("cnic", {
                          required: 'Please enter your cnic number.',
                        })}
                      />
                    </div>

                  </div>

                  {/* ============================================================== */}
                  <div className="title w-full mb-4">
                    <h1 className="text-[22px] font-semibold text-qblack mb-1">
                      Business Information
                    </h1>
                    <p className="text-[15px] text-qgraytwo">
                      Fill the form below or write us .We will help you as soon
                      as possible.
                    </p>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <InputCom
                        placeholder="Brand Name"
                        label="Brand Name"
                        type="text"
                        inputClasses="h-[50px]"
                        register={register("brandName")}
                      />
                      <InputCom
                        placeholder="Business Name"
                        label="Business Name*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.businessName?.message}
                        register={register("businessName", {
                          required: 'Please enter your business name.',
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <InputCom
                        placeholder="+021 *********"
                        label="Business Contact*"
                        type="tel"
                        inputClasses="h-[50px]"
                        errors={errors?.businessContact?.message}
                        register={register("businessContact", {
                          required: 'Please enter your business contact.',
                          pattern: {
                            value: businessMobileRegex,
                            message: 'Please enter a valid contact.',
                          }
                        })}
                      />
                      <InputCom
                        placeholder="NTN Number"
                        label="NTN Number"
                        type="text"
                        inputClasses="h-[50px]"
                        register={register("ntn")}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <InputCom
                        placeholder="STRN Number"
                        label="STRN Number"
                        type="text"
                        inputClasses="h-[50px]"
                        register={register("strn")}
                      />
                      <div className="input-com w-full h-full">
                        <div data-aos="fade-up" className="product-size mb-[20px]">
                          <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                            Business Country*
                          </span>
                          <div className="w-full">
                            <Autocomplete
                              disablePortal
                              autoHighlight
                              options={countries}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => { setSelectedCountry(newValue); getCities(1, newValue) }}
                              onInputChange={(event, newInputValue) => Debounce(() => getCountries(newInputValue))}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <div className="input-com w-full h-full">
                        <div data-aos="fade-up" className="product-size mb-[20px]">
                          <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                            Business City*
                          </span>
                          <div className="w-full">
                            <Autocomplete
                              disablePortal
                              autoHighlight
                              disabled={selectedCountry ? false : true}
                              options={cities}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => setSelectedCity(newValue)}
                              onInputChange={(event, newInputValue) => Debounce(() => getCities(1, '', newInputValue))}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <InputCom
                        placeholder="Business Postal Code"
                        label="Business Postal Code*"
                        type="number"
                        inputClasses="h-[50px]"
                        errors={errors?.businessPostalCode?.message}
                        register={register("businessPostalCode", {
                          required: 'Please enter your business postal code.',
                        })}
                      />
                    </div>
                    <div className="mb-5">
                      <InputCom
                        placeholder="Business Address"
                        label="Business Address*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.businessAddress?.message}
                        register={register("businessAddress", {
                          required: 'Please enter your business address.',
                        })}
                      />
                    </div>
                    <div className="mb-5">
                      <InputCom
                        placeholder="Warehouse Address"
                        label="Warehouse Address*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.warehouseAddress?.message}
                        register={register("warehouseAddress", {
                          required: 'Please enter your warehouse address.',
                        })}
                      />
                    </div>
                  </div>

                  {/* ============================================================== */}
                  <div className="title w-full mb-4">
                    <h1 className="text-[22px] font-semibold text-qblack mb-1">
                      Account Information
                    </h1>
                    <p className="text-[15px] text-qgraytwo">
                      Fill the form below or write us .We will help you as soon
                      as possible.
                    </p>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Bank Name"
                        label="Bank Name*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.bankName?.message}
                        register={register("bankName", {
                          required: 'Please enter a bank name.',
                        })}
                      />

                      <InputCom
                        placeholder="Account Title"
                        label="Account Title*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.accountName?.message}
                        register={register("accountName", {
                          required: 'Please enter a account title.',
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Account Number"
                        label="Account Number*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.accountNumber?.message}
                        register={register("accountNumber", {
                          required: 'Please enter a account number.',
                        })}
                      />

                      <InputCom
                        placeholder="IBAN Number"
                        label="IBAN Number*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.iban?.message}
                        register={register("iban", {
                          required: 'Please enter a iban number.',
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Password*"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        inputClasses="h-[50px]"
                        children={
                          <Box sx={{ width: '50px' }}>
                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                            </IconButton>
                          </Box>
                        }
                        errors={errors?.password?.message}
                        register={register("password", {
                          required: 'Please enter password.',
                          pattern: {
                            value: passwordRegex,
                            message: 'Password contain minimum 8 characters, at least uppercase/lowercase letter, number and special character',
                          }
                        })}
                      />

                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Re-enter Password*"
                        name="repassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        inputClasses="h-[50px]"
                        children={
                          <Box sx={{ width: '50px' }}>
                            <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                            </IconButton>
                          </Box>
                        }
                        errors={errors?.confirmPassword?.message}
                        register={register("confirmPassword", {
                          required: 'Please enter confirm password.',
                          validate: value => value === password.current || "Confirm password does not match."
                        })}
                      />
                    </div>
                    <div className="signin-area mb-3">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          disabled={loading}
                          className="black-btn text-sm text-white w-[490px] h-[50px] font-semibold flex justify-center bg-purple items-center"
                        >
                          <span>Create Seller Account</span>
                          {loading && (
                            <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                              <LoaderStyleOne />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="signup-area flex justify-center">
                      <p className="text-sm text-qgraytwo font-normal">
                        Alrady have an Account?
                        <a href="/login" className="ml-2 text-qblack">
                          Log In
                        </a>
                      </p>
                    </div>
                  </div>

                </form>
                <div>
                  <div className="mb-10">
                    <div className="update-cover w-full">
                      <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                        Upload Cheque Book Slip
                      </h1>
                      <div>
                        <div className="w-full relative h-[150px] w-[230px] upload-box" onClick={() => chequeInput.current.click()}>
                          {chequeBookSlip &&
                            <img
                              src={chequeBookSlip}
                              alt=""
                              className="w-full h-[150px] w-[230px] rounded-lg overflow-hidden object-cover"
                            />
                          }
                          <input
                            ref={chequeInput}
                            accept="image/png, image/jpg, image/jpeg"
                            type="file"
                            className="hidden"
                            onChange={(e) => handleUploadImage(e, 'chequeBook')}
                          />
                          {!chequeBookSlip &&
                            <p>Browse to find or drag image here</p>
                          }
                        </div>
                        {errors?.chequeBook?.message &&
                          <p className="text-[12px] text-qred">*{errors?.chequeBook?.message}</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="update-cover w-full">
                      <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                        Upload FBR Letter
                      </h1>
                      <div>
                        <div className="w-full relative h-[150px] w-[230px] upload-box" onClick={() => fbrInput.current.click()}>
                          {fbrLetter &&
                            <img
                              src={fbrLetter}
                              alt=""
                              className="w-full h-[150px] w-[230px] rounded-lg overflow-hidden object-cover"
                            />
                          }
                          <input
                            ref={fbrInput}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={(e) => handleUploadImage(e, 'fbr')}
                            type="file"
                            className="hidden"
                          />
                          {!fbrLetter &&
                            <p>Browse to find or drag image here</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="update-cover w-full">
                      <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                        Upload CNIC Front
                      </h1>
                      <div>
                        <div className="w-full relative h-[150px] w-[230px] upload-box" onClick={() => cnicFrontInput.current.click()}>
                          {cnicFront &&
                            <img
                              src={cnicFront}
                              alt=""
                              className="w-full h-[150px] w-[230px] rounded-lg overflow-hidden object-cover"
                            />
                          }
                          <input
                            ref={cnicFrontInput}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={(e) => handleUploadImage(e, 'cnicFront')}
                            type="file"
                            className="hidden"
                          />
                          {!cnicFront &&
                            <p>Browse to find or drag image here</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="update-cover w-full">
                      <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                        Upload CNIC Back
                      </h1>
                      <div>
                        <div className="w-full relative h-[150px] w-[230px] upload-box" onClick={() => cnicBackInput.current.click()}>
                          {cnicBack &&
                            <img
                              src={cnicBack}
                              alt=""
                              className="w-full h-[150px] w-[230px] rounded-lg overflow-hidden object-cover"
                            />
                          }
                          <input
                            ref={cnicBackInput}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={(e) => handleUploadImage(e, 'cnicBack')}
                            type="file"
                            className="hidden"
                          />
                          {!cnicBack &&
                            <p>Browse to find or drag image here</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
