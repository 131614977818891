import { Star, StarBorder } from "@mui/icons-material";
import Compair from "../icons/Compair";
import QuickViewIco from "../icons/QuickViewIco";
// import Star from "../icons/Star";
import ThinLove from "../icons/ThinLove";
import { baseUrl } from "../../../config/axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function ProductCardRowStyleTwo({ className, datas, type }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div
      data-aos="fade-left"
      className={`product-row-card-style-one w-full h-[250px] bg-white group relative overflow-hidden ${className || ""
        }`}
    >
      <div className="flex space-x-5 items-center w-full h-full lg:p-[20px] sm:p-4 p-2">
        <div
          onClick={() => navigate(`/single-product/${datas?.id ? datas?.id : datas?.product_id}`, { state: { flashSaleId: datas?.flashSaleId } })}
          className="lg:w-1/2 w-1/3 h-full"
        >
          <img
            src={`${baseUrl + datas?.images[0]?.imagePath + datas?.images[0]?.image}`}
            alt=""
            className="w-full h-full object-contain"
          />
        </div>
        <div className="flex-1 flex flex-col justify-center h-full">
          <div>
            {/* reviews */}
            <div className="flex space-x-1 mb-3">
              {/* {Array.from(Array(datas?.average_rating), () => (
                <span key={5 + Math.random()}>
                  <Star sx={{ color: '#FFA800', fontSize: '20px' }} />
                </span>
              ))} */}
              {Array.from(Array(5), () => (
                <span key={datas?.average_rating + Math.random()}>
                  <StarBorder sx={{ color: '#FFA800', fontSize: '20px' }} />
                </span>
              ))}
            </div>
            <Link to={`/single-product/${datas?.id ? datas?.id : datas?.product_id}`} state={{ flashSaleId: datas?.flashSaleId }}>
              <p className="title mb-2 sm:text-[15px] text-[13px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">
                {datas?.product_name}
              </p>
            </Link>
            <p className="price mb-[26px]">
              <span className="main-price text-qgray line-through font-600 sm:text-[18px] text-base">
                {datas?.discounted_price > 0 && `PKR${datas?.price}`}
              </span>
              {datas?.flash_sale_price > 0 ? (
                <span className="offer-price text-qred font-600 sm:text-[18px] text-base ml-2">
                  PKR{datas?.flash_sale_price}
                </span>
              ) : (
                <span className="offer-price text-qred font-600 sm:text-[18px] text-base ml-2">
                  PKR{datas?.discounted_price > 0 ? `${datas?.discounted_price}` : `${datas?.price}`}
                </span>
              )}
            </p>
            <button type="button" onClick={() => navigate(`/single-product/${datas?.id ? datas?.id : datas?.product_id}`, { state: { flashSaleId: datas?.flashSaleId } })} className="w-[110px] h-[30px]">
              <span className={type === 3 ? 'blue-btn' : 'yellow-btn'}> Add To Cart</span>
            </button>
          </div>
        </div>
      </div>
      {/* quick-access-btns */}
      {/* <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-[30px]  transition-all duration-300 ease-in-out">
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <QuickViewIco />
          </span>
        </a>
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <ThinLove />
          </span>
        </a>
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <Compair />
          </span>
        </a>
      </div> */}
    </div>
  );
}
