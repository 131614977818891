import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function RefundPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Refund Policy", path: "refund-policy" },
            ]}
            title="Refund Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Returns and Refunds
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We want you to be completely satisfied with your purchase. If you are not satisfied with your purchase for any reason, you may return it for a refund within 7 Days of the purchase date.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Return Process
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                To initiate a return, please follow these steps:
              </p>
              <div>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Contact our customer support to request a return authorization.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Pack the items securely and include a copy of the original packing slip or order confirmation.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Ship the items to the address provided by our customer support.
                  </li>
                </ul>
              </div>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Refund Criteria
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Items must be in their original condition, unused, and in the original packaging.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Shipping and handling charges are non-refundable.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Refunds will be issued to the original payment method used during the purchase.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Refund Timeline
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Refunds will be processed within 7 Days of receiving the returned items. The time it takes for the refund to appear on your account may vary based on your financial institution.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Contact Us
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                If you have any questions about our shipping or refund policy, please contact us at helpdesk@hashtag.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
