import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { AttachFile, ChatBubble, Close, SendOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useAuth } from '../../context/UseContext';
import { baseUrl } from '../../config/axios';
import Pusher from 'pusher-js';
import ChatServices from '../../apis/chat/ChatServices';
import { ErrorToaster } from '../Helpers/toaster/Toaster';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

function Chat() {

  const { user } = useAuth();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const [chatScreen, setChatScreen] = useState(false);

  const [conversation, setConversation] = useState([]);
  const [conversationId, setConversationId] = useState();

  const [uploadedFile, setUploadedFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [imgBaseUrl, setImgBaseUrl] = useState();

  // *For Handle Open Chat Screen
  const handleOpenChat = () => {
    try {
      if (user?.token) {
        setChatScreen(true)
      } else {
        ErrorToaster('Kindly login first')
        navigate('/login')
      }
    } catch (error) {
      console.log('handleOpenChat => error:', error)
    }
  }

  // *For Upload
  const handleUpload = (e, type) => {
    if (e.target.value !== "") {
      setUploadedFile(e.target.files[0])
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        setPreviewFile(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  // *For Get All Conversation and Filter the Admin
  const getConversations = async () => {
    try {
      const { data } = await ChatServices.getConversations()
      const adminConversationId = data.find(e => e.opposite_user?.id == 1)?.id
      setConversationId(adminConversationId)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Conversations By Id
  const getConversationMessages = async () => {
    try {
      const { imageBaseUrl, data } = await ChatServices.getConversationMessages(conversationId)
      setImgBaseUrl(imageBaseUrl)
      const messages = []
      data?.forEach(e => {
        let obj = {
          conversationId: e?.conversation_id,
          date: e?.created_at,
          message: e?.message,
          attachment: e?.attachment,
          userId: e?.user_id
        }
        messages.push(obj)
      })
      setConversation(messages.reverse())
    } catch (error) {
      console.log("🚀 ~ file: index.js:23 ~ getConversations ~ error:", error)
    }
  }

  // *For Send Message
  const createConversation = async (data) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append('message', data.message)
      if (uploadedFile) {
        formData.append('attachment', uploadedFile)
      }
      if (conversation.length > 0) {
        await ChatServices.sendMessage(conversationId, formData)
      } else {
        formData.append('receiver', 1) // Id 1 is Admin
        await ChatServices.createConversation(formData)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false);
      reset()
      getConversationMessages()
      setUploadedFile()
      setPreviewFile()
    }
  }

  useEffect(() => {
    if (chatScreen) {
      getConversationMessages()
    }
  }, [chatScreen]);

  useEffect(() => {
    if (user?.token) {
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'ap2',
        channelAuthorization: {
          endpoint: baseUrl + 'api/channelAuthorization',
          headers: { 'Authorization': `Bearer ${user?.token}` }
        }
      });

      const channel = pusher.subscribe('private-messages-channel.' + user.id);

      channel.bind('message.created', (data) => {
        console.log('pusher message =>', data);
      });

      getConversations()
    }
  }, [user]);

  return (
    <Fragment>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />

      <Box
        sx={{
          position: 'fixed',
          right: '15px',
          bottom: '25px',
          zIndex: '9999',
        }}
      >
        {chatScreen ? (
          <Box
            sx={{
              width: '350px',
              height: 'calc(100vh - 100px)',
              borderRadius: '6px',
              bgcolor: '#fff',
              boxShadow: '0 4px 20px 0 rgba(0,7,22,.12)'
            }}
          >
            <Box sx={{ bgcolor: '#234386', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" color="white">
                Chat With Us
              </Typography>
              <IconButton
                onClick={() => setChatScreen(false)}
                sx={{
                  color: '#fff',
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box sx={{ p: 1, height: 'calc(100vh - 215px)', overflow: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
              {conversation.map((item, index) => (
                <Box key={index} sx={{ maxWidth: '95%', my: 1, width: 'fit-content', ml: user?.id === item?.userId && 'auto', mr: user?.id !== item?.userId && 'auto' }}>
                  <Typography component={'p'} variant="caption" sx={{ textAlign: user?.id === item?.userId ? 'left' : 'right', color: '#323B4B' }}>
                    {moment(item.created_at).format('DD.MM.YYYY HH:mm a')}
                  </Typography>
                  <Box sx={{ borderRadius: '4px', bgcolor: '#d9d9d9', p: 1.5 }}>
                    {item?.attachment &&
                      <Box
                        component={'img'}
                        src={imgBaseUrl + item?.attachment}
                        sx={{ maxWidth: '250px', height: 'auto', objectFit: 'contain' }}
                      />
                    }
                    <Typography variant="body2" sx={{ color: '#323B4B' }}>
                      {item.message}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box component="form" onSubmit={handleSubmit(createConversation)} sx={{ borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px', p: 1, borderTop: '1px solid #b6bec9' }}>
              {previewFile &&
                <Box sx={{ position: 'relative' }}>
                  <Box sx={{ position: 'absolute', left: '0px', bottom: '0px', width: '100%', height: 'calc(100vh - 210px)', bgcolor: '#d9d9d9' }}>
                    <IconButton color="primary" onClick={() => { setPreviewFile(); setUploadedFile() }} sx={{ position: 'absolute', right: 10, top: 10 }}>
                      <Close sx={{ width: '16px', height: '16px', color: '#FF2121' }} />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                      <Box
                        component={'img'}
                        src={previewFile}
                        sx={{ maxWidth: '310px', maxHeight: '310px', objectFit: 'contain' }}
                      />
                    </Box>
                  </Box>
                </Box>
              }
              <TextField
                fullWidth
                size="small"
                placeholder={'Ask a question'}
                {...register("message")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton component="label" edge="start" onClick={() => fileInputRef.current.click()}>
                        <AttachFile color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type='submit' edge="end" >
                        {loading ? (
                          <CircularProgress size={20} sx={{ color: '#234386' }} />
                        ) : (
                          <SendOutlined sx={{ color: '#234386' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        ) : (
          <IconButton
            onClick={() => handleOpenChat()}
            sx={{
              p: 2,
              color: '#fff',
              bgcolor: '#234386',
              '&:hover': {
                bgcolor: '#234386',
              }
            }}
          >
            <ChatBubble />
          </IconButton>
        )}
      </Box>
    </Fragment>
  );
}

export default Chat;