import { get } from '..';
import WebSectionRoutes from './Website.Routes';

const WebsiteServices = {
  getWebSection: async (params) => {
    const data = await get(WebSectionRoutes.getWebSection, params);
    return data;
  },
}

export default WebsiteServices