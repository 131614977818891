import { useEffect, useState } from "react";
import datas from "../../data/products.json";
import SectionStyleFour from "../Helpers/SectionStyleFour";
import SectionStyleOne from "../Helpers/SectionStyleOne";
import SectionStyleThree from "../Helpers/SectionStyleThree";
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import Layout from "../Partials/Layout";
import Ads from "./Ads";
import Banner from "./Banner";
import BestSellers from "./BestSellers";
import BrandSection from "./BrandSection";
import CampaignCountDown from "./CampaignCountDown";
import ProductsAds from "./ProductsAds";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import ProductServices from "../../apis/product/ProductServices";
import ShopServices from "../../apis/shop/ShopServices";
import CommonServices from "../../apis/common/CommonServices";
import Categories from "./Categories";
import CategoryServices from "../../apis/category/CategoryServices";

export default function Home() {
  const { productss } = datas;
  const brandss = [];
  productss.forEach((product) => {
    brandss.push(product.brand);
  });
  const [ads, setAds] = useState(false);
  const adsHandle = () => {
    setAds(false);
  };
  useEffect(() => {
    setAds(true);
  }, []);

  // *For Products
  const [products, setProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);

  // *For Brands
  const [brands, setBrands] = useState([]);
  const [brandBaseUrl, setBrandBaseUrl] = useState();

  // *For Top Selling Products
  const [topSellingProducts, setTopSellingProducts] = useState([]);

  // *For Top Seller
  const [topSeller, setTopSeller] = useState([]);

  // *For Flash Sales
  const [flashSales, setFlashSales] = useState([]);
  const [saleBaseUrl, setSaleBaseUrl] = useState([]);

  // *For Campaigns
  const [campaignsBanner, setCampaignsBanner] = useState([]);
  const [campaignsMidSection, setCampaignsMidSection] = useState([]);

  // *For Categories
  const [categories, setCategories] = useState([]);
  const [fullBaseUrl, setFullBaseUrl] = useState('');

  // *For Get Products
  const getProducts = async (page, limit) => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 7
      }
      const { data } = await ProductServices.getProducts(params)
      setProducts(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Brands
  const getBrands = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 12
      }
      const { imageBaseUrl, data } = await ProductServices.getBrands(params)
      setBrands(data)
      setBrandBaseUrl(imageBaseUrl)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Top Selling Products
  const getTopSellingProducts = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 4
      }
      const { data } = await ProductServices.getTopSellingProducts(params)
      setTopSellingProducts(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Featured Products
  const getFeaturedProducts = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 7,
        is_feature: 1
      }
      const { data } = await ProductServices.getProducts(params)
      setFeaturedProducts(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Top Seller
  const getTopSellers = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 6
      }
      const { data } = await ShopServices.getTopSellers(params)
      setTopSeller(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Flash Sale
  const getFlashSales = async () => {
    try {
      const { data, imageBaseUrl } = await CommonServices.getFlashSales()
      setFlashSales(data)
      setSaleBaseUrl(imageBaseUrl)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Campaigns
  const getCampaigns = async () => {
    try {
      const { data, imageBaseUrl } = await CommonServices.getCampaigns()
      const campImage = []
      const campMidImage = []
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (index <= 2) {
          campImage.push(imageBaseUrl + element?.images[0]?.image_path)
        } else {
          campMidImage.push(imageBaseUrl + element?.images[0]?.image_path)
        }
      }
      if (campImage.length > 0) {
        setCampaignsBanner(campImage)
      } else {
        setCampaignsBanner([`${process.env.PUBLIC_URL}/assets/images/banner-1.png`])
      }
      setCampaignsMidSection(campMidImage)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Categories
  const getCategories = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 12
      }
      const { data, imageBaseUrl } = await CategoryServices.getCategories(params)
      setFullBaseUrl(imageBaseUrl)
      setCategories(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getProducts();
    getBrands();
    getTopSellingProducts();
    getFeaturedProducts();
    getTopSellers();
    getFlashSales();
    getCampaigns();
    getCategories();
  }, []);

  return (
    <>
      <Layout>
        {/* {ads && <Ads handler={adsHandle} />} */}
        {/* <div className="btn w-5 h-5 "></div> */}
        {campaignsBanner.length > 0 &&
          <Banner
            className="banner-wrapper mb-[30px]"
            data={campaignsBanner}
          />
        }
        <ViewMoreTitle
          className="best-sallers-section mb-[30px]"
          // seeMoreUrl="/sellers"
          categoryTitle="Categories"
          viewMore={false}
        >
          <Categories
            data={categories}
            baseUrl={fullBaseUrl}
          />
        </ViewMoreTitle>
        <SectionStyleOne
          products={products}
          brands={brandss}
          categoryTitle="Mobile & Tablet"
          sectionTitle="All Products"
          seeMoreUrl="/all-products"
          className="category-products mb-[30px]"
        />
        <BrandSection
          sectionTitle="Shop by Brand"
          className="brand-section-wrapper mb-[30px]"
          data={brands}
          baseUrl={brandBaseUrl}
        />
        {flashSales?.length > 0 &&
          <CampaignCountDown
            className="mb-[30px]"
            data={flashSales}
            saleBaseUrl={saleBaseUrl}
          />
        }
        <div id="top-selling">
          <ViewMoreTitle
            className="top-selling-product mb-[30px]"
            seeMoreUrl="/all-products"
            categoryTitle="Top Selling Products"
          >
            <SectionStyleTwo
              products={topSellingProducts}
            />
          </ViewMoreTitle>
        </div>
        <div id="top-seller">
          <ViewMoreTitle
            className="best-sallers-section mb-[30px]"
            seeMoreUrl="/sellers"
            categoryTitle="Best Seller"
          >
            <BestSellers data={topSeller} />
          </ViewMoreTitle>
        </div>
        {campaignsMidSection.length > 0 &&
          <ProductsAds
            ads={campaignsMidSection}
            sectionHeight="sm:h-[295px] h-full"
            className="products-ads-section mb-[30px]"
          />
        }
        {featuredProducts?.length > 0 &&
          <div style={{ backgroundColor: '#2343860f', padding: '20px 0px', marginBottom: '20px' }}>
            <SectionStyleOne
              categoryBackground={`${process.env.PUBLIC_URL}/assets/images/section-category-2.jpg`}
              products={featuredProducts}
              brands={brandss}
              categoryTitle="Electronics"
              sectionTitle="Featured Products"
              seeMoreUrl="/all-products"
              className="category-products mb-[30px]"
            />
          </div>
        }
        {/* <ProductsAds
          ads={[`${process.env.PUBLIC_URL}/assets/images/ads-3.png`]}
          className="products-ads-section mb-[30px]"
        /> */}
        <div id="new-arrivals">
          <SectionStyleThree
            products={products}
            sectionTitle="New Arrivals"
            seeMoreUrl="/all-products"
            className="new-products mb-[30px]"
          />
        </div>
        {/* <ProductsAds
          sectionHeight="164"
          ads={[`${process.env.PUBLIC_URL}/assets/images/ads-4.png`]}
          className="products-ads-section mb-[60px]"
        /> */}
        {/* <SectionStyleFour
          products={productss}
          sectionTitle="Popular Sales"
          seeMoreUrl="/all-products"
          className="category-products mb-[60px]"
        /> */}
      </Layout>
    </>
  );
}
