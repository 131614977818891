import { useEffect, useState } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import { Box, CircularProgress } from "@mui/material";
import WebsiteServices from "../../apis/website/WebsiteServices";

export default function Tutorial() {

  // *For Loading
  const [loader, setLoader] = useState(false);

  // *For Tutorial
  const [tutorial, setTutorial] = useState();

  // *For Image baseUrl
  const [baseUrl, setBaseUrl] = useState('');

  // *For Get Web Sections
  const getWebSections = async () => {
    setLoader(true)
    try {
      let params = {
        section: 'tutorial',
      }
      const { data, imageBaseUrl } = await WebsiteServices.getWebSection(params)
      setTutorial(data[0])
      setBaseUrl(imageBaseUrl)
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getWebSections()
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Tutorial", path: "tutorial" },
            ]}
            title="Tutorial"
          />
        </div>
        <div className="aboutus-wrapper w-full">
          <div className="container-x mx-auto">
            {loader ? (
              <Box sx={{ height: { xs: '250px', md: '500px' }, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={60} />
              </Box>
            ) : (
              <div className="w-full lg:flex lg:space-x-12 pb-10 lg:pb-0">
                <div className="content flex-1">
                  <div
                    dangerouslySetInnerHTML={{ __html: tutorial?.text }}
                  />
                </div>
                <div className="md:w-[500px] w-full md:h-[500px] h-auto rounded overflow-hidden my-5 lg:my-0">
                  <img
                    src={baseUrl + `/${tutorial?.section}/` + tutorial?.image}
                    alt="tutorial"
                    className="w-full h"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
