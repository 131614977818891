import { useEffect, useState } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import CommonServices from "../../apis/common/CommonServices";

export default function TermsCondition() {

  const [termsConditions, setTermsConditions] = useState();

  // *For Terms Condition
  const getTermsConditions = async () => {
    try {
      const { data } = await CommonServices.getTermsConditions()
      setTermsConditions(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:12 ~ getTermsConditions ~ error:", error)
    }
  }

  useEffect(() => {
    // getTermsConditions()
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Terms and condition", path: "/terms-conditions" },
            ]}
            title="Terms and Condition"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full">
              <div dangerouslySetInnerHTML={{ __html: termsConditions }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
