const CommonRoutes = {
  contactUs: 'contactUS',
  getFaqs: 'getFaqs',
  getCountries: 'countries',
  getCities: 'citiesByCountryId/',
  getFlashSales: 'getFlashSale',
  getFlashSaleDetail: 'getFlashSaleDetailById/',
  getCampaigns: 'getCampaigns',
  getVouchers: 'getVouchers',
  getVat: 'buyer/getVAT',
  applyVoucher: 'buyer/getVoucherDetailByCode/',
  getTermsConditions: '',
  getUserExternalStores: 'dropshipper/getUserExternalStores',
  getCouriers: 'getCouriers',

};

export default CommonRoutes