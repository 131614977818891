import { get, post } from '../../apis';
import ChatRoutes from './Chat.Routes';

const ChatServices = {
  getConversations: async () => {
    const data = await get(ChatRoutes.getConversations);
    return data;
  },
  getSingleConversation: async (userId) => {
    const data = await get(ChatRoutes.getSingleConversation + userId);
    return data;
  },
  getConversationMessages: async (userId) => {
    const data = await get(ChatRoutes.getConversationMessages + userId);
    return data;
  },
  createConversation: async (obj) => {
    const data = await post(ChatRoutes.createConversation, obj);
    return data;
  },
  sendMessage: async (userId, obj) => {
    const data = await post(ChatRoutes.sendMessage + userId, obj);
    return data;
  },
}

export default ChatServices