import { get } from '../../apis';
import CategoryRoutes from './Category.Routes';

const CategoryServices = {
  getCategories: async (params) => {
    const data = await get(CategoryRoutes.getCategories, params);
    return data;
  },
  getSubcategories: async (params) => {
    const data = await get(CategoryRoutes.getSubcategories, params);
    return data;
  },
}

export default CategoryServices