import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Privacy Policy", path: "privacy-policy" },
            ]}
            title="Privacy Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Introduction
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                This Privacy Policy outlines how hashtag.com.co ("we," "us," or "our") collects, uses, discloses, and protects your personal information when you use our website (https://www.hashtag.com.co) and our services (collectively, the "Services"). We are committed to safeguarding your privacy and ensuring the security of your personal information.
                <br />
                By accessing or using our Services, you consent to the practices described in this Privacy Policy. Please read this Privacy Policy carefully and make sure you understand it. If you do not agree with our practices, please do not use our Services.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Information We Collect
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                We may collect the following types of information:
              </p>
              <div>
                <h2 className="text-[18px] font-medium text-qblack mb-5">
                  2.1. Personal Information:
                </h2>

                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Contact Information: This may include your name, email address, phone number, and postal address.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Account Information: When you create an account, we may collect your username and password.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Billing Information: If you make a purchase through our Services, we may collect payment card details or other payment information.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Communication Data: Information you provide when you contact us, such as customer support requests or feedback.
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-[18px] font-medium text-qblack mb-5">
                  2.2. Usage Information:
                </h2>

                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Log Data: Information about your use of our Services, including your IP address, device type, browser type, and pages you visit.
                  </li>
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to collect information about your browsing behavior and preferences.
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-[18px] font-medium text-qblack mb-5">
                  2.3. User-Generated Content:
                </h2>

                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qgraytwo leading-7">
                    Content you provide through our Services, such as reviews, comments, or forum posts.
                  </li>
                </ul>
              </div>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. How We Use Your Information
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may use your information for the following purposes:
              </p>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Providing and improving our Services.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Processing transactions and providing customer support.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Personalizing your experience and offering tailored content.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Communicating with you, including sending important updates and notifications.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Conducting research, analytics, and marketing activities.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Complying with legal obligations and protecting our rights.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Information Sharing
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may share your information with the following third parties:
              </p>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Service Providers: We may share your information with third-party service providers who help us operate our Services.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Business Partners: We may share information with trusted business partners who offer complementary services.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Legal Compliance: We may disclose your information to comply with legal obligations or respond to lawful requests.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                5. Your Choices
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                You have the following rights:

              </p>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Access and Correction: You can access and update your personal information through your account settings.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Opt-Out: You can opt out of marketing communications by following the instructions in the communication.
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Delete Your Account: You can request the deletion of your account and associated data by contacting us.
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                6. Security
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We implement reasonable security measures to protect your information. However, no data transmission over the internet can be guaranteed as 100% secure.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                7. Children's Privacy
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us to have it removed.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                8. Changes to This Privacy Policy
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the revised Privacy Policy on our website or by other means.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                9. Contact Us
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at:
              </p>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qgraytwo leading-7">
                  Email: electromania5172@gmail.com
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Phone: +(92) 3413131113
                </li>
                <li className="text-[15px] text-qgraytwo leading-7">
                  Phone: +(92) 3452218146
                </li>
              </ul>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                10. Applicable Law
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                This Privacy Policy is governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.
                <br />
                By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
