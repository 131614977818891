import { useEffect, useState } from "react";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { ErrorToaster, SuccessToaster } from "../Helpers/toaster/Toaster";
import { useLocation, useNavigate } from "react-router-dom";
import AuthServices from "../../apis/auth/AuthServices";
import { Dialog, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import InputCom from "../Helpers/InputCom";
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import { cardRegex, cvvRegex, expireMonthRegex, expireYearRegex } from "../../utils";

export default function MembershipPlan() {

  const { state } = useLocation()
  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);

  const [membershipPlans, setMembershipPlans] = useState([]);

  const getMembershipPlan = async () => {
    try {
      const { data } = await AuthServices.getMembershipPlan(state?.userType)
      setMembershipPlans(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Registration
  const registration = async (memberShipId) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("name", state?.name)
      formData.append("username", state?.username)
      formData.append("contact", state?.phone)
      formData.append("email", state?.email)
      formData.append("password", state?.password)
      formData.append("password_confirmation", state?.confirmPassword)
      formData.append("cnic", state?.cnic)
      formData.append("user_type", state?.userType)
      formData.append("business_name", state?.businessName)
      formData.append("brand_name", state?.brandName)
      formData.append("business_contact", state?.businessContact)
      formData.append("ntn_no", state?.ntn)
      formData.append("strn_no", state?.strn)
      formData.append("business_country", state?.businessCountry)
      formData.append("business_city", state?.businessCity)
      formData.append("business_postal_code", state?.businessPostalCode)
      formData.append("business_address", state?.businessAddress)
      formData.append("warehouse_address", state?.warehouseAddress)
      formData.append("membership_plan_id", memberShipId)
      formData.append("cheque_book_slip", state?.chequeBookFile)
      formData.append("fbr_letter", state?.fbrFile)
      formData.append("cnic_front", state?.cnicFrontFile)
      formData.append("cnic_back", state?.cnicBackFile)
      formData.append("account_number", state?.accountNumber)
      formData.append("account_name", state?.accountName)
      formData.append("bank_name", state?.bankName)
      formData.append("iban", state?.iban)
      // formData.append("card_number", data?.cardNumber)
      // formData.append("card_cvv", data?.cardCvv)
      // formData.append("card_expiry_month", data?.cardMonth)
      // formData.append("card_expiry_year", data?.cardYear)
      const { message } = await AuthServices.register(formData)
      SuccessToaster(message)
      navigate('/')
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMembershipPlan()
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">

      <Dialog
        maxWidth="xs"
        open={openCardDialog}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
      >
        <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Card Detail</DialogTitle>
        <form onSubmit={handleSubmit(registration)}>
          <div className="mb-5">
            <InputCom
              placeholder="4242 4242 4242 4242"
              label="Card Number*"
              type="number"
              inputClasses="h-[50px]"
              errors={errors?.cardNumber?.message}
              register={register("cardNumber", {
                required: 'Please enter a card number.',
                pattern: {
                  value: cardRegex,
                  message: 'Please enter a valid card number.',
                }
              })}
            />
          </div>
          <div className="mb-5">
            <InputCom
              placeholder="***"
              label="CVV"
              type="number"
              inputClasses="h-[50px]"
              errors={errors?.cardCvv?.message}
              register={register("cardCvv", {
                required: 'Please enter a card cvv.',
                pattern: {
                  value: cvvRegex,
                  message: 'Please enter a valid cvv number.',
                }
              })}
            />
          </div>
          <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
            <InputCom
              placeholder="month"
              label="Expiry Month"
              type="number"
              inputClasses="h-[50px]"
              errors={errors?.cardMonth?.message}
              register={register("cardMonth", {
                required: 'Please enter a card expiry month.',
                pattern: {
                  value: expireMonthRegex,
                  message: 'Please enter a valid expiry month.',
                }
              })}
            />
            <InputCom
              placeholder="year"
              label="Expiry Year"
              type="number"
              inputClasses="h-[50px]"
              errors={errors?.cardYear?.message}
              register={register("cardYear", {
                required: 'Please enter a card expiry year.',
                pattern: {
                  value: expireYearRegex,
                  message: 'Please enter a valid expiry year.',
                }
              })}
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="black-btn text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
          >
            <span>Register</span>
            {loading && (
              <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                <LoaderStyleOne />
              </span>
            )}
          </button>
        </form>
      </Dialog>

      <div className="become-saller-wrapper w-full">
        <div className="title mb-10">
          <PageTitle
            title="Membership Plan"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "membership plan", path: "/membership-plan" },
            ]}
          />
        </div>
        <div className="content-wrapper w-full mb-10">
          <div className="container-x mx-auto">
            <div className="w-full bg-white sm:p-[30px] p-3">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-11">

                {membershipPlans?.map((item, index) => (
                  <div key={index} class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8">
                    <h5 class="mb-4 text-xl font-medium text-gray-500">{item?.name}</h5>
                    <div class="flex items-baseline text-gray-900">
                      <span class="text-3xl font-semibold">$</span>
                      <span class="text-5xl font-extrabold tracking-tight">{item?.price}</span>
                      <span class="ml-1 text-xl font-normal text-gray-500">/{item?.validity}</span>
                    </div>
                    <ul class="space-y-5 my-7">
                      {item?.features.map((feature, i) => (
                        <li key={i} class="flex space-x-3">
                          <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5 text-qblue" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Check icon</title><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                          <span class="text-base font-normal leading-tight text-gray-500">{feature?.key}</span>
                        </li>
                      ))}
                    </ul>
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => registration(item?.id)}
                      class="text-white bgcolor focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full h-[50px] text-center items-center"
                    >
                      <span>Choose plan</span>
                      {loading && (
                        <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                          <LoaderStyleOne />
                        </span>
                      )}
                    </button>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
