import "react-input-range/lib/css/index.css";
import Checkbox from "../Helpers/Checkbox";
import { useEffect, useState } from "react";
import CategoryServices from "../../apis/category/CategoryServices";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setQueryParams } from "../../utils";

export default function ProductsFilter({
  filters,
  checkboxHandler,
  volume,
  volumeHandler,
  storage,
  filterstorage,
  className,
  filterToggle,
  filterToggleHandler,
}) {

  const navigate = useNavigate()

  const [searchParams] = useSearchParams();

  const [categories, setCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [subCategoryToggle, setSubCategoryToggle] = useState([]);

  // *For Get Categories
  const getCategories = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 200
      }
      const { data } = await CategoryServices.getCategories(params)
      setCategories(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Sub Categories
  const getSubCategories = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 200
      }
      const { data } = await CategoryServices.getSubcategories(params)
      setSubCategories(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *Filter By Category
  const handleSelectCategory = (categoryId) => {
    try {
      let updatedCategoryIds;

      if (categoryIds.includes(categoryId)) {
        // Remove category ID if already exists
        updatedCategoryIds = categoryIds.filter(id => id !== categoryId);
      } else {
        // Add category ID if not exists
        updatedCategoryIds = [...categoryIds, categoryId];
      }
      // Update the state and set query parameter
      setCategoryIds(updatedCategoryIds);

      const queryString = setQueryParams({ categories: updatedCategoryIds.join(',') });
      navigate(`?${queryString}`, { replace: true });
    } catch (error) {
      console.log('handleSelectCategory => error:', error)
    }
  }

  // *Filter By SubCategory
  const handleSelectSubCategory = (subCategoryId) => {
    try {
      let updatedSubCategoryIds;

      if (subCategoryIds.includes(subCategoryId)) {
        // Remove sub category ID if already exists
        updatedSubCategoryIds = subCategoryIds.filter(id => id !== subCategoryId);
      } else {
        // Add sub category ID if not exists
        updatedSubCategoryIds = [...subCategoryIds, subCategoryId];
      }
      // Update the state and set query parameter
      setSubCategoryIds(updatedSubCategoryIds);

      const queryString = setQueryParams({ subcategories: updatedSubCategoryIds.join(',') });
      navigate(`?${queryString}`, { replace: true });
    } catch (error) {
      console.log('handleSelectSubCategory => error:', error)
    }
  }

  const handleSubCategoryToggle = (id) => {
    try {
      const isExist = subCategoryToggle.includes(id)
      if (isExist) {
        setSubCategoryToggle(subCategoryToggle.filter(e => e !== id))
      } else {
        setSubCategoryToggle([...subCategoryToggle, id])
      }
    } catch (error) {
      console.log('handleSubCategoryToggle => error:', error)
    }
  }

  useEffect(() => {
    getCategories()
    getSubCategories()
  }, []);

  useEffect(() => {
    const categoriesParam = searchParams.get('categories');
    const subCategoriesParam = searchParams.get('subcategories');
    if (categoriesParam) {
      const categoryArray = categoriesParam.split(',');
      setCategoryIds(categoryArray);
    }
    if (subCategoriesParam) {
      const subCategoryArray = subCategoriesParam.split(',');
      setSubCategoryIds(subCategoryArray);
    }
  }, [searchParams]);

  return (
    <>
      <div
        className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen z-10 lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px] ${className || ""
          }  ${filterToggle ? "block" : "hidden lg:block"}`}
      >
        <div className="filter-subject-item pb-10 border-b border-qgray-border">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">
              Product categories
            </h1>
          </div>
          <div className="filter-items">
            <ul>
              {categories.map((item, index) => (
                <li key={index} className="item mb-5">
                  <div className="flex justify-between items-center mb-3">
                    <div className="flex space-x-[14px] items-center">
                      <div>
                        <Checkbox
                          handleChange={(e) => handleSelectCategory(item.id.toString())}
                          checked={categoryIds?.includes(item.id.toString())}
                        />
                      </div>
                      <div>
                        <label className="text-xs font-black font-400 capitalize">
                          {item?.name}
                        </label>
                      </div>
                    </div>
                    <div>
                      {subCategoryToggle.includes(item.id) ? (
                        <span className="cursor-pointer" onClick={() => handleSubCategoryToggle(item.id)}>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect y="4" width="10" height="2" fill="#C4C4C4" />
                          </svg>
                        </span>
                      ) : (
                        <span className="cursor-pointer" onClick={() => handleSubCategoryToggle(item.id)}>
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect y="4" width="10" height="2" fill="#C4C4C4" />
                            <rect
                              x="6"
                              width="10"
                              height="2"
                              transform="rotate(90 6 0)"
                              fill="#C4C4C4"
                            />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  {subCategoryToggle.includes(item.id) &&
                    <ul className="ml-3">
                      {subCategories.filter(e => e.head_id === item.id)?.map((item, index) => (
                        <li key={index} className="item flex justify-between items-center mb-5">
                          <div className="flex space-x-[14px] items-center">
                            <div>
                              <Checkbox
                                handleChange={(e) => handleSelectSubCategory(item.id.toString())}
                                checked={subCategoryIds?.includes(item.id.toString())}
                              />
                            </div>
                            <div>
                              <label className="text-xs font-black font-400 capitalize">
                                {item?.name}
                              </label>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  }
                </li>
              ))}
              {/* <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="babyItem"
                      name="babyItem"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.babyItem}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="babyItem"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Baby Items
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
        {/* <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Price Range</h1>
          </div>
          <div className="price-range mb-5">
            <InputRange
              draggableTrack
              maxValue={1000}
              minValue={0}
              value={volume}
              onChange={volumeHandler}
            />
          </div>
          <p className="text-xs text-qblack font-400">
            Price: ${volume.min} - ${volume.max}
          </p>
        </div> */}
        {/* <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Brands</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="apple"
                      name="apple"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.apple}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="apple"
                      className="text-xs font-black font-400 capitalize"
                    >
                      apple
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="samsung"
                      name="samsung"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.samsung}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="samsung"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Samsung
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="walton"
                      name="walton"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.walton}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="walton"
                      className="text-xs font-black font-400 capitalize"
                    >
                      walton
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="oneplus"
                      name="oneplus"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.oneplus}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="oneplus"
                      className="text-xs font-black font-400 capitalize"
                    >
                      oneplus
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="vivo"
                      name="vivo"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.vivo}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="vivo"
                      className="text-xs font-black font-400 capitalize"
                    >
                      vivo
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="oppo"
                      name="oppo"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.oppo}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="oppo"
                      className="text-xs font-black font-400 capitalize"
                    >
                      oppo
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="xiomi"
                      name="xiomi"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.xiomi}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="xiomi"
                      className="text-xs font-black font-400 capitalize"
                    >
                      xiomi
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="others"
                      name="others"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.others}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="others"
                      className="text-xs font-black font-400 capitalize"
                    >
                      others
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
        {/* <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Storage</h1>
          </div>
          <div className="filter-items">
            <div className="flex space-x-[5px] flex-wrap">
              <span
                onClick={() => filterstorage("64GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "64GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                64GB
              </span>
              <span
                onClick={() => filterstorage("128GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "128GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                128GB
              </span>
              <span
                onClick={() => filterstorage("256GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "256GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                256GB
              </span>
              <span
                onClick={() => filterstorage("512GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "512GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                512GB
              </span>
              <span
                onClick={() => filterstorage("1024GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "1024GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                1024GB
              </span>
            </div>
          </div>
        </div> */}
        {/* <div className="filter-subject-item pb-10 mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Sizes</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeS"
                      name="sizeS"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeS}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeS"
                      className="text-xs font-black font-400 capitalize"
                    >
                      s
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeM"
                      name="sizeM"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeM}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeM"
                      className="text-xs font-black font-400 capitalize"
                    >
                      M
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeXL"
                      name="sizeXL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeXL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeXL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      XL
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeXXL"
                      name="sizeXXL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeXXL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeXXL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      XXL
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeFit"
                      name="sizeFit"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeFit}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeFit"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Sliem Fit
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
        <button
          onClick={filterToggleHandler}
          type="button"
          className="w-10 h-10 fixed top-5 right-5 z-50 rounded  lg:hidden flex justify-center items-center border border-qred text-qred"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
