import { useSelector } from "react-redux";
import ThinBag from "../../../Helpers/icons/ThinBag";
import Middlebar from "./Middlebar";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import { useEffect, useState } from "react";
import { ErrorToaster } from "../../../Helpers/toaster/Toaster";
import AuthServices from "../../../../apis/auth/AuthServices";

export default function HeaderOne({ className, drawerAction, type = 1 }) {

  const { cart } = useSelector(state => state.userDataReducer)

  const [userTypes, setUserTypes] = useState([]);

  // *For Get User Types
  const getUserTypes = async () => {
    try {
      const { data } = await AuthServices.getUserTypes()
      setUserTypes(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getUserTypes()
  }, []);

  return (
    <header className={` ${className || ""} header-section-wrapper relative`}>
      <TopBar className="quomodo-shop-top-bar" userTypes={userTypes} />
      <Middlebar type={type} className="quomodo-shop-middle-bar lg:block hidden" />
      <div className="quomodo-shop-drawer lg:hidden block w-full h-[60px] bg-white">
        <div className="w-full h-full flex justify-between items-center px-5">
          <div onClick={drawerAction}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
          </div>
          <div>
            {type === 3 ? (
              <a href="/">
                <img
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/hashtag-logo.png`}
                  alt="hashtag"
                />
              </a>
            ) : type === 4 ? (
              <a href="/">
                <img
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/hashtag-logo.png`}
                  alt="hashtag"
                />
              </a>
            ) : (
              <a href="/">
                <img
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/hashtag-logo.png`}
                  alt="hashtag"
                />
              </a>
            )}
          </div>
          <div className="cart relative cursor-pointer">
            <a href="/cart">
              <span>
                <ThinBag />
              </span>
            </a>
            <span className={`badge w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow text-qblack'}`}>
              {cart?.length}
            </span>
          </div>
        </div>
      </div>
      <Navbar type={type} userTypes={userTypes} className="quomodo-shop-nav-bar lg:block hidden" />
    </header>
  );
}
