import { get, post } from '..';
import ReviewRoutes from './Review.Routes';

const ReviewServices = {
  getReviewsByProductId: async (id) => {
    const data = await get(ReviewRoutes.getReviewsByProductId + id);
    return data;
  },
  addReview: async (obj) => {
    const data = await post(ReviewRoutes.addReview, obj);
    return data;
  },
}

export default ReviewServices