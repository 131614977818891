import moment from "moment/moment";

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// *Password Regex
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_~=@#$>.<,[{}()!%\|'"`:;+*?&])[A-Za-z\d_~=@#$>.<,[{}()!%\|'"`:;+*?&]{8,}$/
// export const passwordRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/

// *Mobile Regex
export const mobileRegex = /^(\+92)?(0)?3[0-9]{9}$/

// *Business Mobile Regex
export const businessMobileRegex = /^\+[0-9]{7,15}$/

// *Card Regex
export const cardRegex = /^\d{16}$/
// export const cardRegex = /^4[0-9]{12}(?:[0-9]{3})?$/
export const cvvRegex = /^[0-9]{3}$/
export const expireMonthRegex = /^(0?[1-9]|1[012])$/
export const expireYearRegex = /^\d{4}$/

// *Manufacturer Type Id
export const ManufacturerTypeId = 2

// *Drop Shipper Type Id
export const DropShipperTypeId = 3

// *Supplier Type Id
export const SupplierTypeId = 4

// *Capitalize
export const Capitalize = (str) => {
  const array = str.split(" ");

  for (var i = 0; i < array.length; i++) {
    array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);
  }
  const capitalString = array.join(" ");
  return capitalString
}

// *For Date Format
export const getDateFormate = (date) => {
  if (!date) return
  const newDate = moment(date).format('YYYY-MM-DD')
  if (newDate === 'Invalid date') {
    return ''
  }
  return newDate
}

export const getDate = (date) => {
  if (!date) return
  const newDate = moment(date).format('DD-MM-YYYY')
  if (newDate === 'Invalid date') {
    return ''
  }
  return newDate
}

export const getDateTime = (date) => {
  if (!date) return
  const newDate = moment(date).format('DD-MMM-YYYY  h:mm A')
  return newDate
}

// *For Debounce
let debounceTimer;
export const Debounce = (func, delay = 1000) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(func, delay);
};

// *For Set Query Params
export const setQueryParams = (params) => {
  const searchParams = new URLSearchParams(window.location.search);
  Object.keys(params).forEach(key => {
    if (params[key] !== undefined && params[key] !== null && params[key] !== '') {
      searchParams.set(key, params[key]);
    } else {
      searchParams.delete(key);
    }
  });
  return searchParams.toString();
};