import { post, get } from '..';
import CommonRoutes from './Common.Routes';

const CommonServices = {
  contactUs: async (obj) => {
    const data = await post(CommonRoutes.contactUs, obj);
    return data;
  },
  getFaqs: async () => {
    const data = await get(CommonRoutes.getFaqs);
    return data;
  },
  getCountries: async (params) => {
    const data = await get(CommonRoutes.getCountries, params);
    return data;
  },
  getCities: async (id, params) => {
    const data = await get(CommonRoutes.getCities + id, params);
    return data;
  },
  getFlashSales: async () => {
    const data = await get(CommonRoutes.getFlashSales);
    return data;
  },
  getFlashSaleDetail: async (id, param) => {
    const data = await get(CommonRoutes.getFlashSaleDetail + id, param);
    return data;
  },
  getCampaigns: async () => {
    const data = await get(CommonRoutes.getCampaigns);
    return data;
  },
  getVouchers: async (params) => {
    const data = await get(CommonRoutes.getVouchers, params);
    return data;
  },
  getVat: async () => {
    const data = await get(CommonRoutes.getVat);
    return data;
  },
  applyVoucher: async (code) => {
    const data = await get(CommonRoutes.applyVoucher + code);
    return data;
  },
  getTermsConditions: async () => {
    const data = await get(CommonRoutes.getTermsConditions);
    return data;
  },
  getUserExternalStores: async (params) => {
    const data = await get(CommonRoutes.getUserExternalStores, params);
    return data;
  },
  getCouriers: async (params) => {
    const data = await get(CommonRoutes.getCouriers, params);
    return data;
  },
}

export default CommonServices