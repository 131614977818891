export const errorHandler = (error) => {
  console.log('errorHandler => error:', error)
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.replace('/login')
    }
    return error.response.data.error ? error.response.data.error : error.response.data.message;
  } else if (error.request) {
    return error.message;
  } else {
    return 'Something went wrong';
  }
};
