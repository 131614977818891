import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function ShippingPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Shipping Policy", path: "shipping-policy" },
            ]}
            title="Shipping Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                1. Shipping Methods and Timelines
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                We strive to process and ship orders as quickly as possible. Our estimated processing and shipping times are as follows:
              </p>
              <p className="text-[15px] text-qgraytwo leading-7">
                Order Processing Time: 3-5 business days
                <br />
                Shipping Time: It depends on the chosen shipping method, e.g., standard, expedited, etc
                <br />
                Shipping Costs
                <br />
                Shipping costs are calculated based on the selected shipping method, the total weight of the products in the order, and the destination. Customers will be provided with the shipping cost during the checkout process.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                2. Order Tracking
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                Once your order is shipped, we will provide you with a tracking number via email. You can track the progress of your shipment using the provided tracking information.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                3. Shipping Restrictions
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Currently, we only offer shipping within Pakistan. We do not ship to other international destinations at the moment.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-5">
                4. Shipping Address
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Please ensure that your shipping address is accurate and complete to avoid any delays in the delivery of your order. We are not responsible for packages that are lost or delayed due to incorrect or incomplete addresses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
