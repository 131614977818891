import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import Tutorial from "./components/Tutorial/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";
import { Fragment } from "react";
import { ToasterContainer } from "./components/Helpers/toaster/Toaster";
import MembershipPlan from "./components/MembershipPlan";
import { useAuth } from "./context/UseContext";
import ShippingPolicy from "./components/ShippingPolicy";
import RefundPolicy from "./components/RefundPolicy";
import Chat from "./components/Chat/index.js";

export default function Routers() {

  const { user } = useAuth();

  return (
    <Fragment>

      {/* ========== Chat ========== */}
      <Chat />

      {/* ========== Toaster ========== */}
      <ToasterContainer />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/all-products" element={<AllProductPage />} />
        <Route exact path="/single-product/:id" element={<SingleProductPage />} />
        <Route exact path="/cart" element={<CardPage />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/flash-sale/:id" element={<FlashSale />} />
        <Route exact path="/seller-page/:id" element={<SallerPage />} />
        <Route exact path="/sellers" element={<Sallers />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blogs/blog" element={<Blog />} />
        <Route exact path="/tracking-order" element={<TrackingOrder />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route element={user?.token ? <Navigate to="/" /> : <Outlet />} >
          <Route exact path="/login" element={<Login />} />
        </Route>
        <Route element={user?.token ? <Outlet /> : <Navigate to="/login" />} >
          <Route exact path="/checkout" element={<CheakoutPage />} />
        </Route>
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/register" element={<BecomeSaller />} />
        <Route exact path="/membership-plan" element={<MembershipPlan />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/shipping-policy" element={<ShippingPolicy />} />
        <Route exact path="/refund-policy" element={<RefundPolicy />} />
        <Route exact path="/terms-condition" element={<TermsCondition />} />
        <Route exact path="/tutorial" element={<Tutorial />} />
        <Route exact path="*" element={<FourZeroFour />} />
      </Routes>

    </Fragment>
  );
}
