import ProductCardRowStyleOne from "./Cards/ProductCardRowStyleOne";
import DataIteration from "./DataIteration";

export default function SectionStyleTwo({ className, products, type }) {
  return (
    <div
      className={`section-content w-full grid sm:grid-cols-3 grid-cols-2 xl:gap-[15px] gap-3 ${className || ""
        }`}
    >
      {/* <DataIteration datas={products} startLength={0} endLength={4}>
        {({ datas }) => (
          <div key={datas.id} className="item w-full">
            <ProductCardRowStyleOne type={type} datas={datas} />
          </div>
        )}
      </DataIteration> */}
      {products?.map((item, index) => (
        <div key={index} className="item">
          <ProductCardRowStyleOne type={type} datas={item} />
        </div>
      ))}
    </div>
  );
}
