import { useDispatch, useSelector } from "react-redux";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Debounce, DropShipperTypeId, emailRegex, mobileRegex } from "../../utils";
import ProductServices from "../../apis/product/ProductServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UseContext";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import { clearCart } from "../../redux/slices/userDataSlice";
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import CommonServices from "../../apis/common/CommonServices";
import { Autocomplete, Box, Dialog, DialogTitle, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function CheakoutPage() {

  const dispatch = useDispatch();
  const { cart, totalAmount } = useSelector(state => state.userDataReducer)
  const navigate = useNavigate()
  const { user } = useAuth()
  const plPdfInput = useRef(null);

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // *For Select
  const [billCountries, setBillCountries] = useState([]);
  const [selectedBillCountry, setSelectedBillCountry] = useState(null);
  const [shipCountries, setShipCountries] = useState([]);
  const [selectedShipCountry, setSelectedShipCountry] = useState(null);
  const [billCities, setBillCities] = useState([]);
  const [selectedBillCity, setSelectedBillCity] = useState(null);
  const [shipCities, setShipCities] = useState([]);
  const [selectedShipCity, setSelectedShipCity] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('cod');

  const [openVoucherDialog, setOpenVoucherDialog] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [voucherError, setVoucherError] = useState('');

  const [is3plSelected, setIs3plSelected] = useState(false);
  const [plPdfFile, setPlPdfFile] = useState('');
  const [plPdf, setPlPdf] = useState();
  const [isAllowedByDaraz, setIsAllowedByDaraz] = useState(true);

  const [updatedCart, setUpdatedCart] = useState([]);

  let totalSellingAmount = 0

  // *For Get Bill Countries
  const getBillCountries = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCountries(params)
      setBillCountries(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCountries ~ error:", error)
    }
  }

  // *For Get Ship Countries
  const getShipCountries = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCountries(params)
      setShipCountries(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCountries ~ error:", error)
    }
  }

  // *For Get Bill Cities
  const getBillCities = async (page, country, search) => {
    try {
      const countryId = country ? country.id : selectedBillCountry?.id
      let params = {
        search: search,
        page: 1,
        limit: 100
      }
      const { data } = await CommonServices.getCities(countryId, params)
      setBillCities(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCities ~ error:", error)
    }
  }

  // *For Get Ship Cities
  const getShipCities = async (page, country, search) => {
    try {
      const countryId = country ? country.id : selectedShipCountry?.id
      let params = {
        search: search,
        page: 1,
        limit: 100
      }
      const { data } = await CommonServices.getCities(countryId, params)
      setShipCities(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCities ~ error:", error)
    }
  }

  // *For Get Couriers
  const getCouriers = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCouriers(params)
      setCouriers(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCouriers ~ error:", error)
    }
  }

  // *For Place Order
  const placeOrder = async (formData) => {
    setLoading(true)
    try {
      if (user?.token) {
        if (!selectedBillCountry) {
          ErrorToaster('Pease select bill country')
          return
        }
        if (!selectedBillCity) {
          ErrorToaster('Pease select bill city')
          return
        }
        if (!selectedShipCountry) {
          ErrorToaster('Pease select ship country')
          return
        }
        if (!selectedShipCity) {
          ErrorToaster('Pease select ship city')
          return
        }
        if (!selectedCourier) {
          ErrorToaster('Pease select courier service')
          return
        }
        if (is3plSelected) {
          if (selectedShipCity?.name !== 'Karachi' || selectedBillCity?.name !== 'Karachi') {
            ErrorToaster('3pl is only allowed in karachi')
            return
          }
          if (!isAllowedByDaraz) {
            ErrorToaster('Products in your carts is not allowed in daraz')
            return
          }
          if (!plPdfFile) {
            ErrorToaster('Pease upload 3PL pdf')
            return
          }
        }
        const products = []
        cart?.forEach(element => {
          let prod = {
            id: element.id,
            qty: element.quantity,
            min_quantity: element.min_quantity,
            product_variation_id: element.size.productVariationId,
            // parent_variation_type_id: element.color.id,
            // child_variation_type_id: element.size.id,
          }
          products.push(prod)
        });
        let obj = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone_number: formData.phone,
          email_address: formData.email,
          ship_to_address: formData.shipAddress,
          ship_country_id: selectedShipCountry?.id,
          ship_city_id: selectedShipCity?.id,
          ship_postal_code: formData.shipPostalCode,
          bill_to_address: formData.billAddress,
          bill_country_id: selectedBillCountry?.id,
          bill_city_id: selectedBillCity?.id,
          bill_postal_code: formData.billPostalCode,
          return_address: formData.returnAddress,
          products: JSON.stringify(products),
          payment_method: paymentMethod,
          courier_id: selectedCourier?.id,
        }
        if (voucherCode) {
          obj.voucher_code = voucherCode
        }
        const { message } = await ProductServices.placeOrder(obj)
        dispatch(clearCart())
        Swal.fire({
          title: message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: `#234386`,
          confirmButtonText: 'OK'
        }).then(async (result) => {
          navigate('/')
          reset()
        })
      } else {
        ErrorToaster('First you need to login')
        navigate('/login')
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Apply Voucher
  const applyVoucher = async (formData) => {
    setLoading2(true)
    try {
      const { data } = await CommonServices.applyVoucher(formData?.code)
      if (cart.filter(e => e?.seller_id !== data?.seller_id).length === 0) {
        if (totalAmount >= data?.amount_limit) {
          setVoucherCode(formData?.code)
          setDiscountAmount(data?.voucher_discount)
          setOpenVoucherDialog(false)
          reset2()
        } else {
          setVoucherError(`Min Order. PKR${data?.amount_limit}`)
        }
      } else {
        setVoucherError(`Voucher is only applicable when voucher and product seller is same`)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading2(false)
    }
  }

  // *For Remove Voucher
  const removeVoucher = () => {
    setDiscountAmount(0);
    setVoucherCode('')
  }

  // *For Upload Image
  const handleUploadImage = (e, type) => {
    if (e.target.value !== "") {
      setPlPdfFile(e.target.files[0])
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        setPlPdf(event.target.result);
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  // *For Update Cart Data
  const updateCartData = () => {
    try {
      const updateCart = []
      cart.forEach(e => {
        const productPrice = e?.flash_sale_price > 0 ? e?.flash_sale_price : e?.discounted_price > 0 ? e?.discounted_price : e?.price
        let obj = {
          ...e,
          productTotal: productPrice * e.quantity,
          sellingTotal: productPrice * e.quantity,
        }
        updateCart.push(obj)
      });
      setUpdatedCart(updateCart)
    } catch (error) {
      console.log('updateCartData => error:', error)
    }
  }

  // *For Add Selling Price in Cart Data
  const addSellingPrice = (index, sellingPrice) => {
    try {
      const cartCopy = updatedCart.map(item => ({ ...item }));
      cartCopy[index].sellingPrice = Number(sellingPrice)
      cartCopy[index].sellingTotal = sellingPrice * cartCopy[index].quantity
      setUpdatedCart(cartCopy)
    } catch (error) {
      console.log('updateCartData => error:', error)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getBillCountries()
    getShipCountries()
    getCouriers()
    updateCartData()
    setValue('firstName', user?.name)
    setValue('lastName', user?.name)
    setValue('email', user?.email)
    setValue('phone', user?.contact)
  }, []);

  useEffect(() => {
    if (is3plSelected) {
      const checkIsAllowedByDaraz = cart.every(e => e.is_allowed_by_daraz === 1)
      setIsAllowedByDaraz(checkIsAllowedByDaraz)
    }
  }, [is3plSelected]);

  return (
    <Layout childrenClasses="pt-0 pb-0">

      {/* ======== Voucher Dialog Box ======= */}
      <Dialog
        maxWidth='xs'
        open={openVoucherDialog}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
      >
        <IconButton color="primary" onClick={() => setOpenVoucherDialog(false)} sx={{ position: 'absolute', right: 13, top: 13 }}>
          <Close />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Apply Voucher</DialogTitle>
        <Box component="form" onSubmit={handleSubmit2(applyVoucher)}>
          <div className="w-full mb-2">
            <InputCom
              label="Apply Voucher"
              placeholder="Enter voucher code"
              inputClasses="w-full h-[50px]"
              errors={errors2?.code?.message}
              register={register2("code", {
                required: 'Please enter your voucher code.',
              })}
            />
          </div>
          <p style={{ color: '#EF262C' }} className="cursor-pointer mb-3 text-[13px] font-600 text-qblack leading-[24px] line-clamp-2">
            {voucherError}
          </p>
          <button onClick={handleSubmit2(applyVoucher)} disabled={loading2} className="w-full h-[50px] black-btn flex justify-center items-center">
            <span className="text-sm font-semibold">
              Apply
            </span>
            {loading2 && (
              <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                <LoaderStyleOne />
              </span>
            )}
          </button>
        </Box>
      </Dialog>

      <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
        <div className="w-full mb-5">
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "checkout", path: "/checkout" },
            ]}
          />
        </div>
        <div className="checkout-main-content w-full">
          <div className="container-x mx-auto">
            {/* <div className="w-full sm:mb-10 mb-5">
              <div className="sm:flex sm:space-x-[18px] s">
                <div className="sm:w-1/2 w-full mb-5 h-[70px]">
                  <a href="#">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Log into your Account
                      </span>
                    </div>
                  </a>
                </div>
                <div className="flex-1 h-[70px]">
                  <a href="#">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Enter Coupon Code
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
            <form onSubmit={handleSubmit(placeOrder)}>
              <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="lg:w-1/2 w-full">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Personal Details
                  </h1>
                  <div className="form-area">
                    <div className="sm:flex sm:space-x-5 items-center mb-6">
                      <div className="sm:w-1/2  mb-5 sm:mb-0">
                        <InputCom
                          label="First Name*"
                          placeholder="First Name"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.firstName?.message}
                          register={register("firstName", {
                            required: 'Please enter a first name.',
                          })}
                        />
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Last Name*"
                          placeholder="Last Name"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.lastName?.message}
                          register={register("lastName", {
                            required: 'Please enter a last name.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <InputCom
                          label="Email Address*"
                          placeholder="emial@gmail.com"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.email?.message}
                          register={register("email", {
                            required: 'Please enter an email address.',
                            pattern: {
                              value: emailRegex,
                              message: 'Please enter a valid email.',
                            }
                          })}
                        />
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Phone Number*"
                          type='tel'
                          placeholder="+92312 *********"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.phone?.message}
                          register={register("phone", {
                            required: 'Please enter a phone number.',
                            pattern: {
                              value: mobileRegex,
                              message: 'Please enter a mobile number.',
                            }
                          })}
                        />
                      </div>
                    </div>
                    <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                      Billing Details
                    </h1>
                    <div className="mb-6">
                      <div className="w-full">
                        <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                          Country*
                        </span>
                        <div className="w-full">
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            options={billCountries}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => { setSelectedBillCountry(newValue); getBillCities(1, newValue) }}
                            onInputChange={(event, newInputValue) => Debounce(() => getBillCountries(newInputValue))}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" mb-6">
                      <div className="w-full">
                        <InputCom
                          label="Address*"
                          placeholder="your address here"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.billAddress?.message}
                          register={register("billAddress", {
                            required: 'Please enter your address.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                          City*
                        </span>
                        <div className="w-full">
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            disabled={selectedBillCountry ? false : true}
                            options={billCities}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => setSelectedBillCity(newValue)}
                            onInputChange={(event, newInputValue) => Debounce(() => getBillCities(1, '', newInputValue))}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Postcode / ZIP*"
                          placeholder=""
                          inputClasses="w-full h-[50px]"
                          errors={errors?.billPostalCode?.message}
                          register={register("billPostalCode", {
                            required: 'Please enter postal code.',
                          })}
                        />
                      </div>
                    </div>
                    <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                      Shipping Details
                    </h1>
                    <div className="mb-6">
                      <div className="w-full">
                        <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                          Country*
                        </span>
                        <div className="w-full">
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            options={shipCountries}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => { setSelectedShipCountry(newValue); getShipCities(1, newValue) }}
                            onInputChange={(event, newInputValue) => Debounce(() => getShipCountries(newInputValue))}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" mb-6">
                      <div className="w-full">
                        <InputCom
                          label="Address*"
                          placeholder="your address here"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.shipAddress?.message}
                          register={register("shipAddress", {
                            required: 'Please enter your address.',
                          })}
                        />
                      </div>
                    </div>
                    <div className=" mb-6">
                      <div className="w-full">
                        <InputCom
                          label="Return Address*"
                          placeholder="your return address here"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.returnAddress?.message}
                          register={register("returnAddress", {
                            required: 'Please enter your return address.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                          City*
                        </span>
                        <div className="w-full">
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            disabled={selectedShipCountry ? false : true}
                            options={shipCities}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => setSelectedShipCity(newValue)}
                            onInputChange={(event, newInputValue) => Debounce(() => getShipCities(1, '', newInputValue))}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Postcode / ZIP*"
                          placeholder=""
                          inputClasses="w-full h-[50px]"
                          errors={errors?.shipPostalCode?.message}
                          register={register("shipPostalCode", {
                            required: 'Please enter postal code.',
                          })}
                        />
                      </div>
                    </div>
                    {/* <div className="flex space-x-2 items-center mb-10">
                    <div>
                      <input type="checkbox" name="" id="create" />
                    </div>
                    <label
                      htmlFor="create"
                      className="text-qblack text-[15px] select-none"
                    >
                      Create an account?
                    </label>
                  </div> */}
                    <div>
                      <h1 className="text-2xl text-qblack font-medium mb-3">
                        Is 3PL
                      </h1>
                      <div className="flex space-x-2 items-center mb-10">
                        <div>
                          <input type="checkbox" name="" id="address" checked={is3plSelected} onClick={() => setIs3plSelected(!is3plSelected)} />
                        </div>
                        <label
                          htmlFor="address"
                          className="text-qblack text-[15px] select-none"
                        >
                          3PL option
                        </label>
                      </div>
                    </div>
                    {is3plSelected &&
                      <div className="update-cover w-full">
                        <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                          Attach a PDF file.
                        </h1>
                        <div>
                          <div className="w-full relative h-[150px] w-[230px] upload-box" onClick={() => plPdfInput.current.click()}>
                            {plPdf &&
                              <img
                                src={plPdf}
                                alt=""
                                className="w-full h-[150px] w-[230px] rounded-lg overflow-hidden object-cover"
                              />
                            }
                            <input
                              ref={plPdfInput}
                              accept="image/png, image/jpg, image/jpeg"
                              type="file"
                              className="hidden"
                              onChange={(e) => handleUploadImage(e, 'plpdf')}
                            />
                            {!plPdf &&
                              <p>Browse to find or drag image here</p>
                            }
                          </div>
                          {errors?.plpdf?.message &&
                            <p className="text-[12px] text-qred">*{errors?.plpdf?.message}</p>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="flex-1">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Order Summary
                  </h1>

                  <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <tbody>
                        {/* table heading */}
                        <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                          <td className="py-3 block whitespace-nowrap">Products</td>
                          <td className="py-3 whitespace-nowrap text-center">Total</td>
                          {user?.type == DropShipperTypeId &&
                            <Fragment>
                              <td className="py-3 whitespace-nowrap text-center">Selling Price</td>
                              <td className="py-3 whitespace-nowrap text-center">Selling Total</td>
                            </Fragment>
                          }
                        </tr>
                        {/* table heading end */}
                        {updatedCart?.map((item, index) => {
                          totalSellingAmount += item?.sellingTotal
                          return (
                            <tr key={index} className="bg-white border-b">
                              <td className="py-3 px-1">
                                <span className="text-base text-qgray whitespace-nowrap">
                                  {item.name}
                                  <sup className="text-[13px] text-qgray ml-2 mt-2">
                                    x{item.quantity}
                                  </sup>
                                </span>
                              </td>
                              <td className="text-center py-3 px-1">
                                <span className="text-base text-qgray whitespace-nowrap">
                                  <span className="text-[15px] text-qgray font-medium">
                                    PKR{item?.productTotal}
                                  </span>
                                </span>
                              </td>
                              {user?.type == DropShipperTypeId &&
                                <Fragment>
                                  <td className="text-center py-3 px-1 w-[70px]">
                                    <InputCom
                                      label=""
                                      placeholder=""
                                      type="number"
                                      inputClasses="h-[30px] px-[8px] text-qblack"
                                      errors={errors?.sellingPrice?.message}
                                      register={register("sellingPrice", {
                                        required: 'Please enter your selling price.',
                                        onChange: e => addSellingPrice(index, e.target?.value)
                                      })}
                                    />
                                  </td>
                                  <td className="text-center py-3 px-1">
                                    <span className="text-base text-qgray whitespace-nowrap">
                                      <span className="text-[15px] text-qgray font-medium">
                                        PKR{item?.sellingTotal}
                                      </span>
                                    </span>
                                  </td>
                                </Fragment>
                              }
                            </tr>
                          )
                        })}
                        <tr className="bg-white border-b">
                          <td className="py-3 px-1">
                            <span className="text-[15px] text-base text-qblack whitespace-nowrap">
                              SUBTOTAL
                            </span>
                          </td>
                          <td className="text-center py-3 px-1">
                            <span className="text-[16px] text-base text-qblack whitespace-nowrap">
                              PKR{totalAmount}
                            </span>
                          </td>
                          {user?.type == DropShipperTypeId &&
                            <Fragment>
                              <td className="text-center py-3 px-1 w-[70px]">
                              </td>
                              <td className="text-center py-3 px-1">
                                <span className="text-[16px] text-base text-qblack whitespace-nowrap">
                                  PKR{totalSellingAmount || totalAmount}
                                </span>
                              </td>
                            </Fragment>
                          }
                        </tr>
                      </tbody>
                    </table>

                    {/* <div className="w-full h-[1px] bg-[#EDEDED]"></div> */}

                    {/* <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          SUBTOTAL
                        </p>
                        <p className="text-[16px] font-medium text-qblack uppercase">
                          {user?.type == DropShipperTypeId ? (
                            `PKR${totalSellingAmount}`
                          ) : (
                            `PKR${totalAmount}`
                          )}
                        </p>
                      </div>
                    </div> */}
                    {discountAmount > 0 &&
                      <div className="mt-[30px]">
                        <div className=" flex justify-between mb-5">
                          <p className="text-[13px] font-medium text-qblack uppercase">
                            Discount
                          </p>
                          <p className="text-[15px] font-medium text-qblack uppercase">
                            PKR{discountAmount}
                          </p>
                        </div>
                      </div>
                    }
                    {/* <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[13px] font-medium text-qblack uppercase">
                          VAT
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          {vat}%
                        </p>
                      </div>
                    </div> */}

                    <div className="w-full mt-[30px]">
                      <div className="sub-total mb-6">
                        <div className="w-full">
                          <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                            Courier Service
                          </span>
                          <div className="w-full">
                            <Autocomplete
                              disablePortal
                              autoHighlight
                              options={couriers}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, newValue) => setSelectedCourier(newValue)}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  variant="standard"
                                />
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                      </div>
                    </div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <div className="w-full">
                        </div>
                        <button type="button" onClick={() => discountAmount > 0 ? removeVoucher() : setOpenVoucherDialog(true)} className="w-[200px] h-[50px] black-btn flex justify-center items-center">
                          <span className="text-sm font-semibold">
                            {discountAmount > 0 ? 'Remove Voucher' : 'Apply Voucher'}
                          </span>
                        </button>
                      </div>

                    </div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-2xl font-medium text-qblack">Total</p>
                        <p className="text-2xl font-medium text-qred">PKR {totalAmount - discountAmount}</p>
                      </div>
                    </div>
                    <div className="shipping mt-[30px]">
                      <ul className="flex flex-col space-y-1">
                        {/* <li className=" mb-5">
                          <div className="flex space-x-2.5 items-center mb-4">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="transfer"
                              />
                            </div>
                            <label
                              htmlFor="transfer"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Direct Bank Transfer
                            </label>
                          </div>
                          <p className="text-qgraytwo text-[15px] ml-6">
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment reference.
                          </p>
                        </li> */}
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="qblue"
                                id="cod"
                                checked={paymentMethod === 'cod'}
                                onClick={() => setPaymentMethod('cod')}
                              />
                            </div>
                            <label
                              htmlFor="cod"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Cash on Delivery
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="qblue"
                                id="paypro"
                                checked={paymentMethod === 'paypro'}
                                onClick={() => setPaymentMethod('paypro')}
                                disabled
                              />
                            </div>
                            <label
                              htmlFor="paypro"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Paypro
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <button type="submit" disabled={loading} className="w-full h-[50px] black-btn flex justify-center items-center mb-2">
                      <span className="text-sm font-semibold">
                        Place Order Now
                      </span>
                      {loading && (
                        <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                          <LoaderStyleOne />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
