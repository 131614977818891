import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setQueryParams } from "../../../utils";

export default function SearchBox({ className, type }) {

  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [value, setValue] = useState(searchParams.get('search'));

  const handleSearch = () => {
    try {
      if (pathname === '/all-products') {
        const queryString = setQueryParams({ search: value });
        navigate(`?${queryString}`, { replace: true });
      } else {
        navigate({
          pathname: '/all-products',
          search: `?search=${value}`,
        })
      }
    } catch (error) {
      console.log('handleSearch => error:', error)
    }
  }

  return (
    <>
      <div
        style={{ borderRadius: '30px', backgroundColor: '#f2f2f2' }}
        className={`w-full h-full flex items-center  border border-qgray-border bg-white ${className || ""}`} >
        <div className="flex items-center px-4">
          <button
            type="button"
            className="w-full text-xs font-500 text-qgray flex justify-between items-center"
          >
            <span className="text-qgray">All Categories</span>
            <span>
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="9.18359"
                  y="0.90918"
                  width="5.78538"
                  height="1.28564"
                  transform="rotate(135 9.18359 0.90918)"
                  fill="#8E8E8E"
                />
                <rect
                  x="5.08984"
                  y="5"
                  width="5.78538"
                  height="1.28564"
                  transform="rotate(-135 5.08984 5)"
                  fill="#8E8E8E"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="w-[1px] h-[22px]" style={{ backgroundColor: '#8E8E8E' }}></div>
        <div className="flex-1 bg-red-500 h-full">
          <input
            type="text"
            className="search-input"
            placeholder="Search Product..."
            defaultValue={searchParams.get('search')}
            style={{ backgroundColor: '#f2f2f2' }}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e?.keyCode === 13)
                handleSearch()
            }}
          />
        </div>
        <button
          style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}
          className={`bgcolor text-white w-[130px] h-full text-sm font-600`}
          type="button"
          onClick={() => handleSearch()}
        >
          Search
        </button>
      </div>
    </>
  );
}
