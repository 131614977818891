import { Box, Chip, Grid } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';

export default function Vouchers({ className, data }) {
    return (
        <div className={`section-style-one ${className || ""}`}>
            <div className={`section-wrapper w-full ${className || ""}`}>
                <div className="container-x mx-auto">
                    <div className=" section-title flex justify-between items-center mb-5 mt-5">
                        <div>
                            <h1 className="sm:text-3xl text-xl font-600 text-qblacktext leading-none">
                                Vouchers
                            </h1>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="products-section w-full">
                            <Grid container spacing={2}>
                                {data?.map((item, index) => (
                                    <Grid key={index} item xs={12} md={3} lg={3}>
                                        <Box sx={{ border: `1px solid #DFE5EB`, pt: 2, pb: 1, borderRadius: 2, bgcolor: '#fff' }}>
                                            <Box sx={{ display: 'flex', px: 2, py: 0.5, borderBottom: `1px dashed #DFE5EB` }}>
                                                <Box>
                                                    <svg aria-hidden="true" focusable="false" fill='#234386' class="fl-interaction-primary" width="40" height="40" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2419 2.94497C13.0128 1.68501 10.9872 1.68501 9.75808 2.94497L9.67801 3.02705C9.08858 3.63129 8.28021 3.97202 7.43609 3.97202H7.10396C5.37423 3.97202 3.97202 5.37424 3.97202 7.10396V7.43609C3.97202 8.28021 3.63129 9.08858 3.02705 9.67801L2.94497 9.75808C1.68501 10.9872 1.68501 13.0128 2.94497 14.2419L3.02705 14.322C3.63129 14.9114 3.97202 15.7198 3.97202 16.5639V16.896C3.97202 18.6258 5.37424 20.028 7.10396 20.028H7.43609C8.28021 20.028 9.08858 20.3687 9.67801 20.973L9.75808 21.055C10.9872 22.315 13.0128 22.315 14.2419 21.055L14.322 20.973C14.9114 20.3687 15.7198 20.028 16.5639 20.028H16.896C18.6258 20.028 20.028 18.6258 20.028 16.896V16.5639C20.028 15.7198 20.3687 14.9114 20.973 14.322L21.055 14.2419C22.315 13.0128 22.315 10.9872 21.055 9.75808L20.973 9.67801C20.3687 9.08858 20.028 8.28021 20.028 7.43609V7.10396C20.028 5.37423 18.6258 3.97202 16.896 3.97202H16.5639C15.7198 3.97202 14.9114 3.63129 14.322 3.02705L14.2419 2.94497ZM9.08333 10.75C10.0038 10.75 10.75 10.0038 10.75 9.08333C10.75 8.16286 10.0038 7.41667 9.08333 7.41667C8.16286 7.41667 7.41667 8.16286 7.41667 9.08333C7.41667 10.0038 8.16286 10.75 9.08333 10.75ZM15.5356 8.46491C15.2102 8.13947 14.6826 8.13947 14.3571 8.46491L8.46456 14.3575C8.13912 14.6829 8.13912 15.2105 8.46456 15.536C8.79 15.8614 9.31763 15.8614 9.64307 15.536L15.5356 9.64342C15.8611 9.31798 15.8611 8.79034 15.5356 8.46491ZM16.5833 14.9167C16.5833 15.8371 15.8371 16.5833 14.9167 16.5833C13.9962 16.5833 13.25 15.8371 13.25 14.9167C13.25 13.9962 13.9962 13.25 14.9167 13.25C15.8371 13.25 16.5833 13.9962 16.5833 14.9167Z"></path></svg>
                                                </Box>
                                                <Box sx={{ ml: 1 }}>
                                                    <p className="title cursor-pointer mb-1 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2">
                                                        PKR{item?.voucher_amount} off {item?.voucher_name}
                                                    </p>
                                                    <p className="title cursor-pointer mb-1 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2">
                                                        Code: <span style={{ color: '#234386' }}>{item?.voucher_code}</span>
                                                    </p>
                                                </Box>
                                            </Box>
                                            <Box sx={{ px: 2, pt: 1, display: 'flex', gap: 1.5 }}>
                                                <Chip
                                                    label={`Min. Order PKR${item?.voucher_amount_limit}`}
                                                    sx={{ fontSize: '12px', fontWeight: 700 }}
                                                />
                                                <Chip
                                                    label={`Exp on ${moment(item?.voucher_expiry).format('DD MMM YYYY')}`}
                                                    sx={{ fontSize: '12px', fontWeight: 700, color: '#FF2121' }}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
